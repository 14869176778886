























import Vue from 'vue';
import Component from 'vue-class-component';
import SetDeviceByPoleNum from '@/components/SetDeviceByPoleNum.vue';
import SetPartNumByDeviceId from '@/components/SetPartNumByDeviceId.vue';
import SetPoleNumByHardwareid from '@/components/SetPoleNumByHardwareid.vue';
import { Global } from '@/store';

@Component({ 
    components: {
        SetDeviceByPoleNum,
        SetPartNumByDeviceId,
        SetPoleNumByHardwareid
}})
export default class ExcelUpload extends Vue {

    @Global.State('readonly_user') readonly_user;

    selected_type = {text: 'Set Device Data By Pole Number', value: 'device-data-by-pole-num'};
    
    get excel_types(){
        return [
            {text: this.$t('Set Device Data By Pole Number'), value: 'device-data-by-pole-num'},
            {text: this.$t('Set Part Number By Device ID'), value: 'part-num-by-device-id'},
            {text: this.$t('Set Pole Number By Hardwareid'), value: 'pole-num-by-hardwareid'}
        ];
    }
}

